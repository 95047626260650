body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px !important;
  background: white !important;
}

.scroll {
  top: 0px;
  left: auto;
  color: rgb(123, 128, 154) !important;
  right: 0px !important;
  width: calc(100% - 270px) !important;
  display: grid !important;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  z-index: 1100;
  position: relative;
  box-shadow: none !important;
  box-sizing: border-box !important;
  min-height: 4.6875rem;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  align-items: center;
  flex-shrink: 0;
  margin-left: 270px;
  border-radius: 0.75rem;
  flex-direction: column !important;
  backdrop-filter: none !important;
  background-color: transparent !important;
}
.scroll::after {
  top: 0px !important;
  left: auto !important;
  color: rgb(52, 71, 103) !important;
  right: 0px !important;
  display: grid !important;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  z-index: 1100;
  position: fixed;
  box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem !important;
  box-sizing: border-box !important;
  margin-top: 10px;
  min-height: 4.6875rem !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  flex-shrink: 0;
  margin-right: 6px;
  border-radius: 0.75rem;
  flex-direction: column !important;
  backdrop-filter: saturate(200%) blur(1.875rem) !important;
  background-color: transparent !important;
}

.searchInput {
  background-color: #18333a !important;
  color: white;
  margin-right: 0px !important;
}

.fxt-template-layout21 {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(to right, #242402, #325900, #173f00);
  padding: 15px;
}
.MuiTabs-indicator {
  background-color: black !important;
}
.Mui-selected {
  color: black !important;
}
/* .fxt-template-animation {
  position: relative;
  z-index: 1;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-layout21 #particles-js {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  opacity: 0.5;
} */

.css-m3lf6h-MuiButtonBase-root-MuiListItemButton-root.Mui-focusVisible {
  background-color: #18333a !important;
}

/* Define keyframes */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.super-app-theme--header {
  background-color: #18333a;
  color: white;
}

.MuiDataGrid-filler {
  background-color: #18333a;
}

.css-tgsonj {
  background-color: white !important;
}

.evenClassRow {
  background-color: #18333a;
  color: white;
}
.oddClassRow {
  background-color: rgb(156, 191, 46);
}
/* .MuiDataGrid-footerContainer{
  background-color: rgb(156, 191, 46);
}
.MuiTablePagination-toolbar{
  color: white !important;
}
.MuiTablePagination-selectIcon,.MuiIconButton-sizeMedium{
  color: white !important;
} */
.MuiDataGrid-cell:focus {
  outline: solid #fff;
}
/* .MuiDataGrid-selectedRowCount{
  color: white;
} */

.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row.Mui-selected {
  background-color: #18333a !important;
  color: white !important;
}
.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover {
  background-color: rgb(156, 191, 46) !important;
}
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}
.MuiDataGrid-scrollbarFiller {
  display: none;
}
.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-menuIconButton {
  color: white !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  background: #18333a !important;
  font-size: 13px !important;
}
.dataGridContant {
  font-size: 12px !important;
}
.css-1essi2g-MuiDataGrid-columnHeaderRow {
  background-color: #18333a !important;
  color: white !important;
}

/* Truck Loader */

body {
  padding: 0px;
  margin: 0px;
  background-color: #f0f0f0;
}

.loader-wrapper {
  position: fixed;
  z-index: 1090;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.loader-box {
  position: absolute;
  top: 30%;
  left: 40%;
  height: 200px;
  width: 200px;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade {
  30% {
    opacity: 0.3;
    left: 7px;
  }
  50% {
    opacity: 0.5;
    left: 6px;
  }

  70% {
    opacity: 0.1;
    left: 4px;
  }

  90% {
    opacity: 0.4;
    left: 2px;
  }
}

@keyframes bg {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -400px;
  }
}

@keyframes updown {
  50% {
    transform: translateY(-20%);
  }

  70% {
    transform: translateY(-10%);
  }
}

@keyframes updown-half {
  50% {
    transform: translateY(-10%);
  }

  70% {
    transform: translateY(-5%);
  }
}

.Mui-checked {
  color: white !important;
}

.css-19kzrtu {
  padding: 0px !important;
}
span {
  font-size: 12px !important;
}
.MuiDataGrid-sortIcon,
.MuiDataGrid-menuIconButton {
  color: #ffff !important;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(148, 147, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.MuiButtonBase-root {
  font-size: 12px !important;
}
.MuiDataGrid-cell {
  font-size: 12px !important;
}

.MuiTablePagination-menuItem {
  top: calc(100% - 180px) !important;
}
#star {
  animation: rotateStar 1s linear infinite;
  transform-origin: 325px 305px; /* Center of the circle */
}

@keyframes rotateStar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ag-paging-panel {
  background: #18333a !important;
  color: white !important;
}
.ag-wrapper {
  color: black !important;
}
.ag-header-row-column {
  background: #18333a !important;
  color: white !important;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
}
.ag-icon {
  color: white !important;
}
.ag-row-selected {
  background: #18333a !important;
  color: white !important;
}
.ag-row-hover {
  background: #9dbf2ef1 !important;
  color: white !important;
  opacity: 98 !important;
}
.ag-row-selected .muiSeletc,
.ag-row-hover .muiSeletc,
.ag-row-selected .muiSeletc svg,
.ag-row-hover .muiSeletc svg {
  color: white !important;
  opacity: 98 !important;
}
.SelectedModelClass .MuiSelect-select,
.Mui-focused {
  background-color: transparent !important;
  border: none;
}
.ag-row-selected .SelectedModelClass .MuiInputBase-root::after {
  border-bottom: 1px solid white !important;
}
.ag-row-selected .SelectedModelClass .MuiInputBase-root::before {
  border-bottom: 1px solid white !important;
}
.SelectedModelClass .MuiInputBase-root::after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.407) !important;
}
.SelectedModelClass .MuiInputBase-root::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.407) !important;
}
.ag-row {
  font-size: 12px !important;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
}
.css-q46f9q-MuiInputBase-root-MuiInput-root {
  font-size: 12px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
  font-size: 12px !important;
}
.ag-checkbox-input-wrapper.ag-checked::after {
  color: #9cbf2e !important;
}

.ag-theme-quartz {
  --ag-active-color: #9cbf2e;
}
.MuiDialog-paperScrollPaper,
.MuiDialog-paperScrollPaper form {
  width: 100% !important;
}

.DrawerName {
  bottom: 56px !important;
  top: 80px !important;
}

.MuiDrawer-paperAnchorBottom {
  bottom: 56px !important;
  top: 80px !important;
}

.TypeClass {
  text-transform: capitalize !important;
}
.MuiAutocomplete-input,
.MuiAutocomplete-option,
.MuiInputBase-inputSizeSmall {
  font-size: 12px !important;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: white;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

.red-background {
  background-color: red;
}

.cellColorAnalysis {
  background-color: #91a84794;
  border: 1px solid #96b82c !important;
}

.ag-report-class {
  /* height: calc(100vh - 200px) !important; */
  height: 400px !important;
}
.ag-theme-quartz .ag-checkbox-input-wrapper {
  transform: scale(1.3);
}

.MuiGrid-root .gridAnalysisItem {
  padding-top: 2px !important;
  padding-left: 5px !important;
}

.ag-analysis-grid {
  height: calc(100vh - 430px) !important;
}
.cell-span {
  background-color: #173f00 !important;
}

.speakToTextButton {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: linear-gradient(30deg, rgb(156, 191, 46) 20%, rgb(18 31 34) 80%);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
  animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running
    pulse;
  align-items: center;
  border: 0;
  margin-right: 5px;
  cursor: pointer;
}

.speakToTextButton:is(:hover, :focus) {
  transform: scale(1.2);
}

@keyframes pulse {
  100% {
    box-shadow: 0 0 0 45px rgba(193, 244, 246, 0);
  }
}
/* .AccordingAnalysis {
  background-color: #c7d59d !important;
}
.AccordingAnalysis1 {
  background-color: #cfd7b4 !important;
} */

.requiredFieldNull {
  background: white !important;
}
.custom-toast {
  color: red !important; /* Text color */
  background-color: white !important; /* Background color */
  text-transform: capitalize;
  border: 3px solid #9cbf2e !important;
  width: 600px;
}

.custom-toast .Toastify__progress-bar {
  background: #4caf50 !important; /* Progress bar color */
}

.ag-grid-helpdesk .ag-header-viewport {
  background-color: #18333a !important;
}
